
import { defineComponent, computed, onBeforeMount, reactive, toRefs, watch } from 'vue'
import KlookHeader from '../../desktop/header.vue'
import routesDesktop from '@/router/desktop' // 目前直接从这里获取菜单列表
import { useRoute, useRouter } from 'vue-router'
import KlookIcon from '@/components/icon.vue'
import { PieChartOutlined } from '@ant-design/icons-vue'

const routes = routesDesktop

export default defineComponent({
  components: {
    KlookIcon,
    KlookHeader,
    PieChartOutlined
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const state: {
      selected: any[]
      open: boolean
    } = reactive({
      selected: [],
      open: false
    })

    onBeforeMount(() => {
      state.selected = [route.name] // 当前url路由名字
    })

    const isSimpleStyle = () => {
      const route = useRoute()
      const hiddenRoutes = ['home']
      const curRoute: any = route.name || ''
      return hiddenRoutes.includes(curRoute)
    }

    const navList = routes.length > 0 ? routes[0].children.filter((item: any) => item.nav) : []

    watch(
      () => route.name,
      () => {
        state.selected = [route.name] // 当前url路由名字
      }
    )
    const jumpNav = (item: any) => {
      if (item.key === route.name) return
      router.push({ name: item.key })
    }

    return {
      ...toRefs(state),
      isSimpleStyle: computed(() => isSimpleStyle()),
      navList,
      jumpNav
    }
  }
})
