
import { computed, defineComponent } from 'vue'
import Cookie from 'js-cookie'
import { URLS } from '@/common/js/constant/index'
import { post } from '@/plugins'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { LogoutOutlined, SafetyCertificateOutlined } from '@ant-design/icons-vue'
import KlookIcon from '@/components/icon.vue'
import KlookLangSelector from './lang-selector.vue'

export default defineComponent({
  components: {
    KlookIcon,
    LogoutOutlined,
    KlookLangSelector,
    SafetyCertificateOutlined
  },
  setup() {
    const store = useStore()
    const router = useRouter()

    const logout = () => {
      post(URLS.logout).then(() => {
        store.commit('user/cleanUserInfo')
        Cookie.remove('isLogin')
        router.push({ name: 'login' })
      })
    }

    // 账户功能菜单
    const menuClick = (item: { key: string }) => {
      switch (item.key) {
        case 'settings':
          router.push({ name: 'settings' })
          break

        default:
          // 默认退出
          logout()
          break
      }
    }

    return {
      logout,
      userName: computed(() => store.state.user.username),
      userType: computed(() => store.state.user.userType),
      menuClick
    }
  }
})
