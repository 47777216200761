import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-61283cc8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "nav_top" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_klook_icon = _resolveComponent("klook-icon")!
  const _component_pie_chart_outlined = _resolveComponent("pie-chart-outlined")!
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_a_menu = _resolveComponent("a-menu")!
  const _component_a_layout_sider = _resolveComponent("a-layout-sider")!
  const _component_klook_header = _resolveComponent("klook-header")!
  const _component_a_layout_header = _resolveComponent("a-layout-header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_klook_transition = _resolveComponent("klook-transition")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_a_layout = _resolveComponent("a-layout")!

  return (_openBlock(), _createBlock(_component_a_layout, { style: {"min-height":"100vh"} }, {
    default: _withCtx(() => [
      _createVNode(_component_a_layout_sider, {
        id: "klook-nav",
        width: "224"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_klook_icon, {
              class: "nav-logo",
              type: 'nav-logo',
              size: [159, 56]
            })
          ]),
          _createVNode(_component_a_menu, {
            theme: "dark",
            mode: "inline",
            selectedKeys: _ctx.selected,
            onClick: _ctx.jumpNav
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navList, (item) => {
                return (_openBlock(), _createBlock(_component_a_menu_item, {
                  key: item.name,
                  class: "menu-item"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_pie_chart_outlined),
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t(item.meta?.title)), 1)
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          }, 8, ["selectedKeys", "onClick"])
        ]),
        _: 1
      }),
      _createVNode(_component_a_layout, {
        id: "klook-main",
        style: _normalizeStyle({ 'margin-left': _ctx.open ? '80px' : '224px' })
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_layout_header, {
            id: "klook-header",
            class: _normalizeClass({ simple: _ctx.isSimpleStyle })
          }, {
            default: _withCtx(() => [
              _createVNode(_component_klook_header)
            ]),
            _: 1
          }, 8, ["class"]),
          _createVNode(_component_a_layout_content, { id: "klook-conetent" }, {
            default: _withCtx(() => [
              _createVNode(_component_klook_transition, null, {
                default: _withCtx(() => [
                  _createVNode(_component_router_view)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["style"])
    ]),
    _: 1
  }))
}